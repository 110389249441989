import { DEFAULT_PAGE_SIZE } from '../../constants';

type ArticlePage<T> = {
  articles?: {
    pagination?: {
      skip?: number;
      take?: number;
    };
    results?: T[];
    resultsCount?: number;
  };
};

type GetPageParamConfigurableProps = {
  options: {
    take: number;
  };
};

export const getNextPageParam = getNextPageParamConfigurable({
  options: { take: DEFAULT_PAGE_SIZE },
});

export function getNextPageParamConfigurable<T>({ options }: GetPageParamConfigurableProps) {
  return (lastPage: ArticlePage<T> | undefined) => {
    if (!lastPage) {
      return undefined;
    }

    const articlesCount =
      (lastPage.articles?.pagination?.skip || 0) + (lastPage.articles?.results?.length || 0);
    const totalArticlesCount = lastPage.articles?.resultsCount || 0;

    const hasNextPage = totalArticlesCount > articlesCount;

    if (!hasNextPage) {
      return undefined;
    }

    return {
      take: options.take,
      skip: articlesCount,
    };
  };
}

export const getPreviousPageParam = getPreviousPageParamConfigurable({
  options: { take: DEFAULT_PAGE_SIZE },
});

export function getPreviousPageParamConfigurable<T>({ options }: GetPageParamConfigurableProps) {
  return (lastPage: ArticlePage<T> | undefined, pages: Array<ArticlePage<T>> | undefined) => {
    if (!lastPage || !pages) {
      return undefined;
    }

    const firstIndex = pages.reduce((memo: number | undefined, page) => {
      if (memo === undefined) {
        return page.articles?.pagination?.skip;
      }
      const pageSkip = page.articles?.pagination?.skip;

      if (pageSkip === undefined) {
        return memo;
      }
      return Math.min(memo, pageSkip);
    }, undefined);

    // Returns undefined if the first index is zero or undefined, meaning that we can be sure that we can paginate
    // backwards after this block.
    if (!firstIndex) {
      return undefined;
    }

    const take = Math.min(firstIndex, options.take);

    return {
      take,
      skip: firstIndex - take,
    };
  };
}
